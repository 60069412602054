import { Component, Input, EventEmitter, SimpleChanges, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { __log, _log, _warn } from '@shared/aux_helper_environment';
import { _debounceDecorator, _timeout, _get, _uniqueElements } from '@shared/aux_helper_functions';

import { SvgBaseComponent, svg_base_template, svg_base_styles } from '../svgBase/svgBase.component';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';
import { first } from 'rxjs/operators';
import {
  EditShelfHeight,
  EditShelfRemove,
  EditShelfProp,
  ChangeViewAction,
  MirrorProdsInShelfs,
  AutoCompleteShelf,
} from 'app/spaces/planograms-editor/store/planogram.store.state';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { normalShelfDecorator } from './svgDecorator-normalShelf';
import { simplePegShelfDecorator } from './svgDecorator-simplePegShelf';
import {
  _calcSizeProd,
  _getTmpShelfId,
  DEFAULT_SHELF_COLOR,
  _aux_getHumanType,
  DEFAULT_OFFSET_MIN_PRD_SEP_X,
} from 'app/spaces/static_common/spaces_aux_helpers';
import { pitShelfDecorator } from './svgDecorator-pitShelf';
import { _addShelfsPrecalcData } from 'app/spaces/static_common/spaces_aux_calc_prod_data';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { SvgCanvasComponent } from '../svgCanvas/svgCanvas.component';
import { svgBaseModule } from '../svgModule/svgBaseModule.component';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { svg_shelf_template } from './svgBaseShelf.template';
import { SharedPromotionsShelfModal } from './shared_promotions_shelf_modal.component';
import { MOD_ID_PREFIX, _removeInternalObjId } from 'app/spaces/static_common/spaces_aux_data_transform';
import { DevSettingsService, FeatureFlagsKeysEnum } from 'core/services/dev-settings.service';

const JSON5 = require('json5');

@Component({
  selector: '[app-svg-base-shelf]',
  template: svg_base_template + svg_shelf_template,
  styles: [svg_base_styles],
})
export class svgBaseShelf extends SvgBaseComponent implements OnChanges {
  constructor(
    public dialog: MatDialog,
    public store: Store,
    public translate: IanTranslateService,
    private configFlags: DevSettingsService
  ) {
    super();
  }

  @ViewChildren('itemChild') prdChilds: QueryList<svgBaseShelf>;

  @Input() fillShelf = DEFAULT_SHELF_COLOR;
  @Input() prods = [];
  @Input() objDataShelf: any = {};
  @Input() baseObj: SvgCanvasComponent = null;
  @Input() type; /*En _aux_getHumanType están toddos los tipos de estantes*/
  @Input() parentModule: svgBaseModule = null;
  @Input() id = _getTmpShelfId();
  @Input() showIndicator = null;
  @Input() filterProds = null;
  @Input() atrrTypesSelected = null;
  @Input() minMaxDataBase = null;
  @Input() horizontalGuidesMeasure = 0;
  @Input() previewMode = false;
  @Input() marginLeft = 0; //DES-1576
  @Input() marginRight = 0; //DES-1576

  _aux_getHumanType = type => _aux_getHumanType(type, this.translate);

  onInitialized = new EventEmitter<svgBaseShelf>();

  self = this;

  templIsready = false;
  canHasChilds = true;

  svgCompType = 'shelf';

  prodInMove = false;
  prodRefInMoveIsSmall = false;

  orientationRightToLeft = false;

  _shelfGuide = {
    position: { x: 0, y: 0 },
    size: { w: 0, h: 0 },
  };

  _shelfGuideWarning = false;

  //data con máximos y mínimos de productos para indicadores
  minMaxData = null;

  horizontalGuidesMeasureItems: number[] = [];

  DES_4510_SPC_FEATURES_PARA_POC_SODIMAC = this.configFlags.hasFeatureFlag(FeatureFlagsKeysEnum['FeatureFlag__DES-4510']);

  //DES-1576
  getTotalWidth() {
    let width = this.size.w;
    let margins = (this.marginLeft || 0) + (this.marginRight || 0);
    let totalWidth = width - margins;

    return totalWidth;
  }

  canSelectProds() {
    return this.baseObj.canSelectProducts();
  }

  setHorizontalGuidesMeasureItems() {
    const diff = Math.max(Number(this.horizontalGuidesMeasure || 0), 0);
    const w = Number(this.size ? this.size.w : 0);

    this.horizontalGuidesMeasureItems = [];

    if (!diff || !w) return null;

    let acc = diff;
    let count = 0;

    while (acc < w && count < 1000) {
      this.horizontalGuidesMeasureItems.push(acc);
      count++;
      acc += diff;
    }
  }

  ngOnInit() {
    this.handleTypes();
    super.ngOnInit();
    this.dragDisabled = true;
    this.strokeWidth = '5px';
    if (!this.type) _warn('NO this.type (init)', this);
  }

  async ngOnChanges(changes: SimpleChanges) {
    this._getMaxRemainingWidthSpaceCache = null;
    this.orientationRightToLeft = this.parentModule.orientationRightToLeft !== undefined ? this.parentModule.orientationRightToLeft : false;
    this.templIsready = false;
    if (changes.type) {
      this.handleTypes();
    }

    this.setHorizontalGuidesMeasureItems();

    await _timeout(0);
    this.templIsready = true;
  }

  postSelect = () => {
    this.calcMinMaxData();
  };

  calcMinMaxData() {
    if (!this.minMaxDataBase) {
      this.minMaxData = null;
      return;
    }

    this.minMaxData = _addShelfsPrecalcData([this as svgBaseShelf]);
    return this.minMaxData;
  }

  handleTypes() {
    switch (this.type) {
      case 'normalShelf-level':
        normalShelfDecorator(this);
        break;
      case 'simplePeg-level':
        simplePegShelfDecorator(this);
        break;
      case 'pitShelf-level':
        pitShelfDecorator(this);
        break;
      default:
        _warn('NO this.type', this);
        break;
    }
  }

  @_debounceDecorator(256)
  async changeType(ev) {
    const newVal = _get(ev, 'value');
    if (!newVal || this.type === newVal) return;

    const oldType = this.type;
    this.type = newVal;

    if (this.prods && this.prods.length) {
      this.dialog.open(GenericConfirmationModalComponent, {
        // TRANSLATE
        width: '550px',
        data: {
          message: this.translate.instant('SPACES.EDITOR.MSG_SHELF_SHOULD_EMPTY_TYPE', null, ''), //'El estante debe estar vacío para cambiar el tipo',
          title: null,
          labelCancel: null,
          labelConfirm: 'ok',
          disableClose: false,
        },
      });
      await _timeout(0);
      this.type = oldType;
      return;
    }

    this.store.dispatch(new EditShelfProp((this.objDataShelf as any).id, 'type', newVal));
  }

  @_debounceDecorator(256)
  async changeHeight(ev) {
    const _resetH = async () => {
      // Resetea objDataShelf.height
      let tmpActualVal = (this as any).objDataShelf.height;
      (this as any).objDataShelf.height = 0;
      await _timeout(0);
      (this as any).objDataShelf.height = tmpActualVal;
    };

    if (ev && ev.target && ev.target.value) {
      let val = this.round(Number(ev.target.value));
      await _resetH();
      if (val <= 10 || val > 1000 || isNaN(val) || val === (this as any).objDataShelf.height) return;

      if (!this.isNewHeightValid(val)) {
        this.baseObj.genericAlert({
          // TRANSLATE
          message: this.translate.instant('SPACES.EDITOR.MSG_SHELF_PRD_HEIGTH_WARNING', null, ''), //'Existen productos en el estante con mayor alto',
        });
        return;
      }

      this.store.dispatch(new EditShelfHeight((this.objDataShelf as any).id, val));
    }
  }

  @_debounceDecorator(256)
  async changeMargin(ev, LEFT: boolean = true) {
    if (ev && ev.target && ev.target.value != null) {
      let val = this.round(Number(ev.target.value));
      if (val < 0 || val > 1000 || isNaN(val)) return;

      if (LEFT && this.marginLeft === val) return;
      if (!LEFT && this.marginRight === val) return;

      if (!this.isNewMarginValid(val, LEFT)) {
        this.baseObj.genericAlert({
          message: this.translate.instant('SPACES.EDITOR.MSG_MODULE_WIDTH_ALERT', null, ''),
        });
        return;
      }

      let _id = (this.objDataShelf as any).id;
      if (_id != null) this.store.dispatch(new EditShelfProp(_id, LEFT ? 'marginLeft' : 'marginRight', val));
    }
  }

  @_debounceDecorator(256)
  async changePitDepth(ev) {
    const _resetD = async () => {
      // Resetea objDataShelf.depth si lo tiene
      if ((this as any).objDataShelf.depth !== undefined) {
        let tmpActualVal = (this as any).objDataShelf.depth;
        (this as any).objDataShelf.depth = 0;
        await _timeout(0);
        (this as any).objDataShelf.depth = tmpActualVal;
      }
    };

    if (ev && ev.target && ev.target.value) {
      let val = this.round(Number(ev.target.value));
      await _resetD();
      const actualDepth = (this as any).objDataShelf.depth || this.parentModule.objData.depth;
      if (val <= 5 || val > 1000 || isNaN(val) || val === actualDepth) return;

      if (!this.isNewDepthValid(val)) {
        this.baseObj.genericAlert({
          // TRANSLATE
          message: this.translate.instant('SPACES.EDITOR.MSG_SHELF_PRD_DEPTH_WARNING', null, ''), //'Existen productos en el estante con mayor profundidad',
        });
        return;
      }

      this.store.dispatch(new EditShelfProp((this.objDataShelf as any).id, 'depth', val));
    }
  }

  addElement(data, $position) {
    // Se extiende con los decorators, ver handleTypes()
  }

  showGuides(data, $position) {
    // Se extiende con los decorators, ver handleTypes()
  }

  isValidPos(data, $position) {
    return false;
    // Se extiende con los decorators, ver handleTypes()
  }

  isValidPromoItem(data, verbose = false) {
    let itemId = data?.itemData?.itemId;
    let promos = this.getSharedActions();

    if (itemId == null || !(promos?.length > 0)) return true;

    let itemScope = _uniqueElements(
      promos.reduce((acc, curr) => {
        return [...acc, ...(curr?.actionScope?.itemIds || []).map(el => String(el))];
      }, [])
    );

    if (verbose) _log('[isValidPromoItem]', { data, itemId, promos, itemScope });

    if (!(itemScope?.length > 0)) return true;

    return itemScope.includes(String(itemId));
  }

  isNewWidthValid(newW: number) {
    // Se extiende con los decorators, ver handleTypes()
    return false;
  }

  isNewHeightValid(newH: number) {
    // Se extiende con los decorators, ver handleTypes()
    return false;
  }

  isNewMarginValid($newValue: number, LEFT: boolean = true) {
    // Se extiende con los decorators, ver handleTypes()
    return false;
  }

  isNewDepthValid(newD: number) {
    // Se extiende con los decorators, ver handleTypes()
    return false;
  }

  onProdMove($event: { event: CdkDragEnd; objData: any }) {
    this.baseObj.moveElement($event.event, $event.objData);
  }

  onProdStartMove($event: { event: CdkDragEnd; objData: any }) {
    this.baseObj.startMoveElement($event.event, $event.objData);
  }

  onProdDrop($event: { event: CdkDragEnd; objData: any }) {
    this.baseObj.dropElement($event.event, $event.objData);
  }

  onClick(ev) {
    if (!this.canSelect) {
      this.baseObj.deselectLast();
      return;
    }
    super.onClick(ev);
  }

  public select(v = true) {
    if (!true) _log('[select shelf]', this);
    this.makeFloatMenu();
    super.select(v);
  }

  setOnTopAnSelect(e) {
    if (true) _log('[setOnTopAnSelect]', e);
    let offset = { x: this.position.x + this.parentModule.position.x, y: this.position.y + this.parentModule.position.y };
    this.baseObj.setOnTopAnSelect(e, offset);
  }

  async _deleteObjConf(forceConf = false) {
    if (!this.menuOnSelect) return;
    if (this.prods.filter(s => !!s).length === 0 && !forceConf) {
      // Si tiene all objets borrados tmb borrar directo
      this._deleteObj();
      return;
    }

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      // TRANSLATE
      width: '550px',
      data: {
        message: this.translate.instant('SPACES.EDITOR.MSG_CONFIRM_DEL_PRODS_SHELF_PRD', null, ''), //'¿Desea continuar con la eliminación del estante?<br><b>Se elminarán todos los productos.</b>',
        title: this.translate.instant('SPACES.EDITOR.MSG_CONFIRM_DEL', null, ''), //'Confirmación de eliminación',
        labelConfirm: this.translate.instant('SPACES.EDITOR.BTN_DEL', null, ''), //'Eliminar',
        labelCancel: this.translate.instant('SPACES.EDITOR.BTN_CANCEL', null, ''), //'Cancelar',
        disableClose: true,
        timeToConfirm: 720,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(async result => {
        await _timeout(32);
        if (result) this._deleteObj();
      });
  }

  _deleteObj() {
    if (!this.menuOnSelect) return;
    if (this.selected) this.baseObj.deselectLast();
    this.store.dispatch(new EditShelfRemove(this.id));
  }

  cleanProds() {
    if (!this.prods.length) return;

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      // TRANSLATE
      width: '550px',
      data: {
        message: this.translate.instant('SPACES.EDITOR.MSG_CONFIRM_DEL_SHELF_PRODS', null, ''), // '¿Desea continuar con la eliminación de todos los productos del estante?',
        title: this.translate.instant('SPACES.EDITOR.MSG_CONFIRM_DEL', null, ''), //'Confirmación de eliminación',
        labelConfirm: this.translate.instant('SPACES.EDITOR.BTN_DEL', null, ''), //'Eliminar',
        labelCancel: this.translate.instant('SPACES.EDITOR.BTN_CANCEL', null, ''), //'Cancelar',
        disableClose: true,
        timeToConfirm: 720,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(async result => {
        await _timeout(32);
        if (result) this._cleanProds();
      });
  }

  _cleanProds() {
    if (!this.prods.length) return;
    this.store.dispatch(new EditShelfProp((this.objDataShelf as any).id, 'prods', []));
  }

  getSharedActions() {
    let promotions = (this.objDataShelf as any)?.promotions || [];
    return promotions;
  }

  openSaredActions() {
    let _id = (this.objDataShelf as any).id;
    let _shelfId = this.id;
    let _promotions = (this.objDataShelf as any)?.promotions || [];
    let _parentModuleId = _removeInternalObjId({ id: this.parentModule?.id }, MOD_ID_PREFIX);

    const dialogRef = this.dialog.open(SharedPromotionsShelfModal, {
      // TRANSLATE
      width: '750px',
      data: {
        id: _id,
        shelfId: _shelfId,
        promotions: _promotions,
        parentModuleId: _parentModuleId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(async result => {
        if (result != null) {
          _log('[openSaredActions]', { result, _parentModuleId });
        }
      });
  }

  mirrorProds() {
    if (!this.prods.length) return;

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      // TRANSLATE
      width: '550px',
      data: {
        message: this.translate.instant('SPACES.EDITOR.MSG_CONFIRM_MIRROR_SHELF_PRODS', null, ''),
        title: this.translate.instant('SPACES.EDITOR.MSG_CONFIRM_MIRROR', null, ''),
        labelConfirm: this.translate.instant('SPACES.EDITOR.BTN_ACEPT', null, ''),
        labelCancel: this.translate.instant('SPACES.EDITOR.BTN_CANCEL', null, ''),
        disableClose: true,
        timeToConfirm: 720,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(async result => {
        await _timeout(32);
        if (result) this._mirrorProds();
      });
  }

  _mirrorProds() {
    if (!this.prods.length) return;
    this.store.dispatch(new MirrorProdsInShelfs([this.id]));
  }

  async autoCompleteShelf() {
    if (this.type === 'normalShelf-level') {
      await this.store.dispatch(await new AutoCompleteShelf(this.id, this._getMaxRemainingWidthSpace()));
    }
  }

  getThisScreenOffset() {
    return {
      x: this.position.x + this.parentModule.position.x,
      y: this.position.y + this.parentModule.position.y,
    };
  }

  _getDragDataBase(event: CdkDragEnd, $elBounding) {
    let nativeElement = _get(event, 'source.element.nativeElement');
    if (!nativeElement) return;

    let nativeElementId = nativeElement.getAttribute('id');
    let elBounding = $elBounding;
    let elCenter = { x: elBounding.x + elBounding.width / 2, y: elBounding.y + elBounding.height / 2 };
    let thisSelfBound = this.getSelfBounding();

    let deltaPos = { x: elCenter.x - thisSelfBound.x, y: elCenter.y - thisSelfBound.y };
    let deltaPosScaled = { x: (elCenter.x - thisSelfBound.x) / this.scale, y: (elCenter.y - thisSelfBound.y) / this.scale };

    return { nativeElementId, deltaPos, deltaPosScaled, elBounding, nativeElement, elCenter };
  }

  addProduct(event: CdkDragEnd, $elbounding, dataObj = null) {
    const { nativeElementId, deltaPos, deltaPosScaled, elBounding, nativeElement } = this._getDragDataBase(event, $elbounding) as any;
    this.prodInMove = false;
    if (!nativeElementId) return;

    // TODO: Que no haya que parsear la data, que ya venga lista en: dataObj
    let itemData = !dataObj ? nativeElement.getAttribute('data-psvg-prod') || null : null;
    let itemDataParsed = dataObj ? dataObj : JSON5.parse(itemData);

    if (!itemDataParsed) {
      _warn('[addProduct] NO itemDataParsed', { event, $elbounding, dataObj, itemData, itemDataParsed });
      return;
    }
    itemDataParsed.size = itemDataParsed.size || _calcSizeProd(itemDataParsed);

    if (!true) _log('\n\n[addProduct]', { nativeElementId, deltaPos, elBounding, deltaPosScaled, itemDataParsed });

    this.addElement(itemDataParsed, deltaPosScaled);
  }

  moveProduct(event: CdkDragEnd, $elbounding, dataObj = null) {
    const { nativeElement, nativeElementId, deltaPos, deltaPosScaled, elBounding } = this._getDragDataBase(event, $elbounding) as any;
    if (!nativeElementId) return;
    this.prodInMove = true;
    this._shelfGuideWarning = false;

    // Resetea las vistas
    this.baseObj.layersStatus.PRODUCTS = true;
    if (!this.baseObj.layersStatus.PITSHELFVIEW) this.store.dispatch(new ChangeViewAction('PITSHELFVIEW'));

    if (!true) _log('\n\n[moveProduct]', { x: deltaPos.x, y: deltaPos.y });

    // TODO: Que no haya que parsear la data, que ya venga lista en: dataObj
    const itemData = !dataObj ? nativeElement.getAttribute('data-psvg-prod') || null : null;
    const itemDataParsed = dataObj ? dataObj : JSON5.parse(itemData);
    if (!itemDataParsed) {
      _warn('[moveProduct] NO itemDataParsed', { event, $elbounding, dataObj, itemData, itemDataParsed });
      return;
    }
    itemDataParsed.size = itemDataParsed.size || _calcSizeProd(itemDataParsed);

    // La imagen de referencia del objeto es más chica que el render del objeto en el estante
    this.prodRefInMoveIsSmall = elBounding.height * 1.1 < itemDataParsed.size.h * this.scale;
    this.showGuides(itemDataParsed, deltaPosScaled);
  }

  stopMoveProduct(event: CdkDragEnd, $elbounding) {
    const { nativeElementId, deltaPos, deltaPosScaled, elBounding } = this._getDragDataBase(event, $elbounding) as any;
    this.prodInMove = false;
    if (!true) _log('\n\n[stopMoveProduct]', { x: deltaPos.x, y: deltaPos.y });
  }

  public floatMenuItemList = [];
  makeFloatMenu() {
    if (!this.menuOnSelect) {
      this.floatMenuItemList = null;
      return;
    }
    this.floatMenuItemList = [
      !(this.objDataShelf as any)._isFirst
        ? {
            icon: 'vertical_align_top',
            text: this.translate.instant('SPACES.EDITOR.ACTION_SHELF_MOVEUP', null, ''), //'Mover arriba',
            onClick: async () => {
              this.parentModule.reorderShelf(false, this.id);
            },
          }
        : null,
      !(this.objDataShelf as any)._isLast
        ? {
            icon: 'vertical_align_bottom',
            text: this.translate.instant('SPACES.EDITOR.ACTION_SHELF_MOVEDOWN', null, ''), //'Mover abajo',
            onClick: async () => {
              this.parentModule.reorderShelf(true, this.id);
            },
          }
        : null,
      'divider',
      {
        icon: 'keyboard_capslock',
        text: this.translate.instant('SPACES.EDITOR.ACTION_SHELF_ADD_UP', null, ''), // 'Agregar estante encima',
        onClick: async () => {
          this.parentModule.addShelf(_get(this, 'objDataShelf.order', 0) - 0.5);
        },
      },
      {
        icon: 'keyboard_capslock',
        flipIconY: true,
        text: this.translate.instant('SPACES.EDITOR.ACTION_SHELF_ADD_DOWN', null, ''), //'Agregar estante debajo',
        onClick: async () => {
          this.parentModule.addShelf(_get(this, 'objDataShelf.order', 0) + 0.5);
        },
      },
      'divider',
      this.prods && this.prods.length
        ? {
            icon: 'clear',
            flipIconY: false,
            text: this.translate.instant('SPACES.EDITOR.ACTION_SHELF_DEL_PRODS', null, ''), //'Eliminar productos',
            onClick: async () => {
              this.cleanProds();
            },
          }
        : null,
      'divider',
      {
        icon: 'delete',
        iconRed: true,
        text: this.translate.instant('SPACES.EDITOR.TTIP_DEL', null, ''), //'Eliminar [del]',
        onClick: () => {
          this._deleteObjConf();
        },
      },
    ].filter(o => !!o);

    if (this.tabSelected === 'CONSTRAINTS') {
      this.floatMenuItemList = [
        {
          icon: 'settings_input_composite',
          text: this.translate.instant('SPACES.EDITOR.ACTION_ADD_CONSTRAINTS', null, ''),
          tooltip: this.translate.instant('SPACES.EDITOR.CONSTRAINS_ALL_SHELF_TTIP', null, ''),
          onClick: async () => {
            this.baseObj.newConstraint({ id: null, selectionId: this.id, svgCompType: this.svgCompType });
          },
        },
      ].filter(o => !!o);
    }
  }

  _collidesWithSomeChildProd(pos, size, id = null) {
    if (!pos || !size) return true;

    let rv = false;

    /*Checkea si colisiona con algún otro (distinto id) producto hijo del estante*/
    this.prdChilds.forEach(p => {
      if (!rv && (!id || id !== p.id) && this._isRectsColliding(p.position, p.size, pos, size)) {
        rv = true;
        return;
      }
    });

    return rv;
  }

  /*Calcula el ancho restante*/
  _getMaxRemainingWidthSpaceCache = null;
  _getMaxRemainingWidthSpace() {
    /*Cachea hasta el próximo cambio*/

    if (this._getMaxRemainingWidthSpaceCache === null) {
      let totalWidth = this.getTotalWidth();
      this._getMaxRemainingWidthSpaceCache = _auxGetMaxRemainingWidthSpace(this.prods, totalWidth, this.type);
    }

    return this._getMaxRemainingWidthSpaceCache;
  }

  __hasShelfAvoidCheckSpace() {
    return (this.objDataShelf as any)?.__shelfAvoidCheckSpace === true;
  }

  _toggleShelfAvoidCheckSpace() {
    const newVal = !this.__hasShelfAvoidCheckSpace();

    this.store.dispatch(new EditShelfProp((this.objDataShelf as any).id, '__shelfAvoidCheckSpace', newVal));
  }

  _getSpaceLimitWarning() {
    if (!this.__hasShelfAvoidCheckSpace()) return false;

    if (!this.isNewHeightValid((this.objDataShelf as any)?.height)) return true;

    return this._getMaxRemainingWidthSpace() < 0;
  }
}

export const _auxGetMaxRemainingWidthSpace = (dataProds, shelfWidth, type, noSep = false) => {
  /*Para normalShelf hace el calculo, pera el resto devuelve el ancho total*/
  let sepX = noSep ? 0 : DEFAULT_OFFSET_MIN_PRD_SEP_X;
  if (type !== 'normalShelf-level') return 0;

  let rv = shelfWidth;

  (dataProds || []).forEach(prd => {
    let _w = _calcSizeProd(prd).w;
    rv -= _w + sepX;
  });

  return rv;
};
